class LocalStorageKeyService {
  private readonly jwt: string;
  private readonly apiKey: string;
  private readonly rt: string;
  private readonly refreshAfter: string;
  private readonly username: string;
  private readonly loginEmail: string;
  private readonly me: string;
  private readonly profileNav: string;
  private readonly acceptedCookiePolicyVersion: string;
  private readonly acceptedPrivacyPolicyVersion: string;
  private readonly acceptedTermsOfServicePolicyVersion: string;
  private readonly mostRecentQuery: string;
  private readonly mostRecentSearchType: string;
  private readonly mostRecentSearchLocations: string;
  private readonly mostRecentSearchBedroom: string;
  private readonly mostRecentSearchCapacity: string;
  private readonly mostRecentSearchPopularLocation: string;
  private readonly mostRecentFilterEventSpaceMin: string;
  private readonly mostRecentFilterEventSpaceMax: string;
  private readonly mostRecentFilterGuestRoomMin: string;
  private readonly mostRecentFilterGuestRoomMax: string;
  private readonly mostRecentFilterCompanyTypes: string;
  private readonly mostRecentFilterFeatureOptions: string;
  private readonly minPopularLocationScore: string;

  constructor() {
    if (window.location.hostname.toLowerCase() === 'localhost') {
      this.jwt = 'dev-jwt';
      this.apiKey = 'dev-apikey';
      this.rt = 'dev-rt';
      this.refreshAfter = 'dev-refreshAfter';
      this.username = 'dev-username';
      this.loginEmail = 'dev-loginEmail';
      this.me = 'dev-me';
      this.profileNav = 'dev-profile-nav';
      this.acceptedCookiePolicyVersion = 'dev-acceptedCookiePolicyVersion';
      this.acceptedPrivacyPolicyVersion = 'dev-acceptedPrivacyPolicyVersion';
      this.acceptedTermsOfServicePolicyVersion = 'dev-acceptedTermsOfServicePolicyVersion';
      this.mostRecentQuery = 'dev-mostRecentQuery';
      this.mostRecentSearchType = 'dev-mostRecentSearchType';
      this.mostRecentSearchLocations = 'dev-mostRecentSearchLocations';
      this.mostRecentSearchBedroom = 'dev-mostRecentSearchBedroom';
      this.mostRecentSearchCapacity = 'dev-mostRecentSearchCapacity';
      this.mostRecentSearchPopularLocation = 'dev-mostRecentSearchPopularLocation';
      this.mostRecentFilterEventSpaceMin = 'dev-mostRecentFilterEventSpaceMin';
      this.mostRecentFilterEventSpaceMax = 'dev-mostRecentFilterEventSpaceMax';
      this.mostRecentFilterGuestRoomMin = 'dev-mostRecentFilterGuestRoomMin';
      this.mostRecentFilterGuestRoomMax = 'dev-mostRecentFilterGuestRoomMax';
      this.mostRecentFilterCompanyTypes = 'dev-mostRecentFilterCompanyTypes';
      this.mostRecentFilterFeatureOptions = 'dev-mostRecentFilterFeatureOptions';
      this.minPopularLocationScore = 'dev-minPopularLocationScore';
      return;
    }

    if (window.location.hostname.toLowerCase().startsWith('qa')) {
      this.jwt = 'qa-jwt';
      this.apiKey = 'qa-apikey';
      this.rt = 'qa-rt';
      this.refreshAfter = 'qa-refreshAfter';
      this.username = 'qa-username';
      this.loginEmail = 'qa-loginEmail';
      this.me = 'qa-me';
      this.profileNav = 'qa-profile-nav';
      this.acceptedCookiePolicyVersion = 'qa-acceptedCookiePolicyVersion';
      this.acceptedPrivacyPolicyVersion = 'qa-acceptedPrivacyPolicyVersion';
      this.acceptedTermsOfServicePolicyVersion = 'qa-acceptedTermsOfServicePolicyVersion';
      this.mostRecentQuery = 'qa-mostRecentQuery';
      this.mostRecentSearchType = 'qa-mostRecentSearchType';
      this.mostRecentSearchLocations = 'qa-mostRecentSearchLocations';
      this.mostRecentSearchBedroom = 'qa-mostRecentSearchBedroom';
      this.mostRecentSearchCapacity = 'qa-mostRecentSearchCapacity';
      this.mostRecentSearchPopularLocation = 'qa-mostRecentSearchPopularLocation';
      this.mostRecentFilterEventSpaceMin = 'qa-mostRecentFilterEventSpaceMin';
      this.mostRecentFilterEventSpaceMax = 'qa-mostRecentFilterEventSpaceMax';
      this.mostRecentFilterGuestRoomMin = 'qa-mostRecentFilterGuestRoomMin';
      this.mostRecentFilterGuestRoomMax = 'qa-mostRecentFilterGuestRoomMax';
      this.mostRecentFilterCompanyTypes = 'qa-mostRecentFilterCompanyTypes';
      this.mostRecentFilterFeatureOptions = 'qa-mostRecentFilterFeatureOptions';
      this.minPopularLocationScore = 'qa-minPopularLocationScore';
      return;
    }

    if (window.location.hostname.toLowerCase().startsWith('stg')) {
      this.jwt = 'stg-jwt';
      this.apiKey = 'stg-apikey';
      this.rt = 'stg-rt';
      this.refreshAfter = 'stg-refreshAfter';
      this.username = 'stg-username';
      this.loginEmail = 'stg-loginEmail';
      this.me = 'stg-me';
      this.profileNav = 'stg-profile-nav';
      this.acceptedCookiePolicyVersion = 'stg-acceptedCookiePolicyVersion';
      this.acceptedPrivacyPolicyVersion = 'stg-acceptedPrivacyPolicyVersion';
      this.acceptedTermsOfServicePolicyVersion = 'stg-acceptedTermsOfServicePolicyVersion';
      this.mostRecentQuery = 'stg-mostRecentQuery';
      this.mostRecentSearchType = 'stg-mostRecentSearchType';
      this.mostRecentSearchLocations = 'stg-mostRecentSearchLocations';
      this.mostRecentSearchBedroom = 'stg-mostRecentSearchBedroom';
      this.mostRecentSearchCapacity = 'stg-mostRecentSearchCapacity';
      this.mostRecentSearchPopularLocation = 'stg-mostRecentSearchPopularLocation';
      this.mostRecentFilterEventSpaceMin = 'stg-mostRecentFilterEventSpaceMin';
      this.mostRecentFilterEventSpaceMax = 'stg-mostRecentFilterEventSpaceMax';
      this.mostRecentFilterGuestRoomMin = 'stg-mostRecentFilterGuestRoomMin';
      this.mostRecentFilterGuestRoomMax = 'stg-mostRecentFilterGuestRoomMax';
      this.mostRecentFilterCompanyTypes = 'stg-mostRecentFilterCompanyTypes';
      this.mostRecentFilterFeatureOptions = 'stg-mostRecentFilterFeatureOptions';
      this.minPopularLocationScore = 'stg-minPopularLocationScore';
      return;
    }

    this.jwt = 'jwt';
    this.apiKey = 'apikey';
    this.rt = 'rt';
    this.refreshAfter = 'refreshAfter';
    this.username = 'username';
    this.loginEmail = 'loginEmail';
    this.me = 'me';
    this.profileNav = 'profile-nav';
    this.acceptedCookiePolicyVersion = 'acceptedCookiePolicyVersion';
    this.acceptedPrivacyPolicyVersion = 'acceptedPrivacyPolicyVersion';
    this.acceptedTermsOfServicePolicyVersion = 'acceptedTermsOfServicePolicyVersion';
    this.mostRecentQuery = 'mostRecentQuery';
    this.mostRecentSearchType = 'mostRecentSearchType';
    this.mostRecentSearchLocations = 'mostRecentSearchLocations';
    this.mostRecentSearchBedroom = 'mostRecentSearchBedroom';
    this.mostRecentSearchCapacity = 'mostRecentSearchCapacity';
    this.mostRecentSearchPopularLocation = 'mostRecentSearchPopularLocation';
    this.mostRecentFilterEventSpaceMin = 'mostRecentFilterEventSpaceMin';
    this.mostRecentFilterEventSpaceMax = 'mostRecentFilterEventSpaceMax';
    this.mostRecentFilterGuestRoomMin = 'mostRecentFilterGuestRoomMin';
    this.mostRecentFilterGuestRoomMax = 'mostRecentFilterGuestRoomMax';
    this.mostRecentFilterCompanyTypes = 'mostRecentFilterCompanyTypes';
    this.mostRecentFilterFeatureOptions = 'mostRecentFilterFeatureOptions';
    this.minPopularLocationScore = 'minPopularLocationScore';
    return;
  }

  public get Jwt() {
    return this.jwt;
  }

  public get ApiKey() {
    return this.apiKey;
  }

  public get Rt() {
    return this.rt;
  }

  public get RefreshAfter() {
    return this.refreshAfter;
  }

  public get Username() {
    return this.username;
  }

  public get LoginEmail() {
    return this.loginEmail;
  }

  public get Me() {
    return this.me;
  }

  public get ProfileNav() {
    return this.profileNav;
  }

  public get AcceptedCookiePolicyVersion() {
    return this.acceptedCookiePolicyVersion;
  }

  public get AcceptedPrivacyPolicyVersion() {
    return this.acceptedPrivacyPolicyVersion;
  }

  public get AcceptedTermsOfServicePolicyVersion() {
    return this.acceptedTermsOfServicePolicyVersion;
  }

  public get MostRecentQuery() {
    return this.mostRecentQuery;
  }

  public get MostRecentSearchType() {
    return this.mostRecentSearchType;
  }

  public get MostRecentSearchLocations() {
    return this.mostRecentSearchLocations;
  }

  public get MostRecentSearchBedroom() {
    return this.mostRecentSearchBedroom;
  }

  public get MostRecentSearchCapacity() {
    return this.mostRecentSearchCapacity;
  }

  public get MostRecentSearchPopularLocation() {
    return this.mostRecentSearchPopularLocation;
  }

  public get MostRecentFilterEventSpaceMin() {
    return this.mostRecentFilterEventSpaceMin;
  }

  public get MostRecentFilterEventSpaceMax() {
    return this.mostRecentFilterEventSpaceMax;
  }

  public get MostRecentFilterGuestRoomMin() {
    return this.mostRecentFilterGuestRoomMin;
  }

  public get MostRecentFilterGuestRoomMax() {
    return this.mostRecentFilterGuestRoomMax;
  }

  public get MostRecentFilterCompanyTypes() {
    return this.mostRecentFilterCompanyTypes;
  }

  public get MostRecentFilterFeatureOptions() {
    return this.mostRecentFilterFeatureOptions;
  }

  public get MinPopularLocationScore() {
    return this.minPopularLocationScore;
  }
}

export const localStorageKeyService = new LocalStorageKeyService();
