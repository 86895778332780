class UrlService {
  private readonly apiBaseUrl: string;
  private readonly chartbrewBaseUrl: string;
  private readonly environmentName: 'DEV' | 'QA' | 'STG' | 'PROD';

  constructor() {
    if (window.location.hostname.toLowerCase() === 'localhost') {
      this.apiBaseUrl = 'http://localhost:1337';
      this.chartbrewBaseUrl = 'https://reports.blackbookbyembark.com/b/';
      this.environmentName = 'DEV';
      return;
    }

    if (window.location.hostname.toLowerCase().startsWith('qa')) {
      this.apiBaseUrl = 'https://qaapi.blackbookbyembark.com';
      this.chartbrewBaseUrl = 'https://reports.blackbookbyembark.com/b/';
      this.environmentName = 'QA';
      return;
    }

    if (window.location.hostname.toLowerCase().startsWith('stg')) {
      this.apiBaseUrl = 'https://stgapi.blackbookbyembark.com';
      this.chartbrewBaseUrl = 'https://reports.blackbookbyembark.com/b/';
      this.environmentName = 'STG';
      return;
    }

    this.apiBaseUrl = 'https://api.blackbookbyembark.com';
    this.chartbrewBaseUrl = 'https://reports.blackbookbyembark.com/b/';
    this.environmentName = 'PROD';
  }

  /** Gets the strapi URL to use depending on the environment. */
  public get ApiBaseUrl() {
    return this.apiBaseUrl;
  }

  public get ChartbrewBaseUrl() {
    return this.chartbrewBaseUrl;
  }

  /** Gets the cognito client ID to use depending on the environment. */
  public get EnvironmentName() {
    return this.environmentName;
  }
}

export const urlService = new UrlService();
