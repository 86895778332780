import qs from 'qs';
import { urlService } from '../url-service';
import { localStorageKeyService } from '../../services/local-storage-key-service';
import { QuestionCatetory, QuestionSubcetegory } from '../../utils/enums';
export class ClientBase {
  private _baseUrl: string;

  constructor() {
    this._baseUrl = urlService.ApiBaseUrl;
  }

  get baseUrl() {
    return this._baseUrl;
  }

  public processResponse<T>(response: Response) {
    return new Promise<T>((resolve, reject) => {
      if (response.ok) {
        if (response.status === 200 || response.status === 201) {
          response
            .json()
            .then(data => resolve(data as T))
            .catch(error => error);
        } else {
          resolve(true as T);
        }
      } else {
        response
          .json()
          .then(reason => reject(reason as T))
          .catch(error => reject(error));
      }
    });
  }

  /** Sets parameters defaults if parameters are undefined */
  public stringifyParameters(parameters: ApiParameters) {
    let params: ApiParameters = { populate: [], ...parameters };
    return qs.stringify(params, {
      encodeValuesOnly: true,
    });
  }

  public getCurrentAuthToken() {
    return new Promise<string>((resolve, reject) => {
      if (sessionStorage.getItem(localStorageKeyService.ApiKey) != null) {
        resolve(sessionStorage.getItem(localStorageKeyService.ApiKey));
        return;
      }
      if (localStorage.getItem(localStorageKeyService.Jwt) == null) {
        reject('No token in local storage');
      }
      if (localStorage.getItem(localStorageKeyService.Jwt) == 'undefined') {
        reject('Token in local storage is undefined');
      }
      let refreshAfter = localStorage.getItem(localStorageKeyService.RefreshAfter)
        ? new Date(localStorage.getItem(localStorageKeyService.RefreshAfter)).getTime()
        : new Date().getDate(); // if RefershAfter is not in local storage, set it to the beginning of today
      let now = new Date().getTime();
      if (now <= refreshAfter) {
        resolve(localStorage.getItem(localStorageKeyService.Jwt));
        return;
      }
      if (now > refreshAfter) {
        let token = localStorage.getItem(localStorageKeyService.Rt);
        if (!token) {
          reject('Could not refresh token');
        }
        fetch(`${this.baseUrl}/api/auth/local/refresh`, {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
            'Accept': 'application/json',
          },
          body: JSON.stringify({ refreshToken: token }),
        })
          .then(response => {
            response.json().then(data => {
              if (!response.ok) {
                reject('Could not refresh token');
                return;
              }
              localStorage.setItem(localStorageKeyService.Jwt, data.jwt);
              let refreshDate = new Date(Date.now() + 10 * 60000); // refresh after ten minutes, 60,000 milliseconds in a minute
              localStorage.setItem(localStorageKeyService.RefreshAfter, refreshDate.toString());
              resolve(data.jwt);
            });
          })
          .catch(() => {
            console.log('Could not refresh token');
            reject('Could not refresh token');
          });
      }
    });
  }

  public checkForDocumentId(idValue: any, client: string, method: string) {
    if (!isNaN(idValue)) throw new Error(`The ${client} ${method} method requires a documentId, not an id.`);
  }
}

export interface SuccessResponse<T> {
  data: DataResponse<T> | null;
  meta?: {
    pagination?: PaginationResponse;
  };
  error?: ErrorResponse;
}

export interface CollectionSuccessResponse<T> {
  data: DataResponse<T>[] | null;
  meta?: {
    pagination?: PaginationResponse;
  };
  error?: ErrorResponse;
}

export interface DataResponse<T> {
  id: number;
  documentId?: string;
  attributes: T & {
    createdAt?: string;
    updatedAt?: Date;
  };
}

export interface PaginationResponse {
  start?: number;
  limit?: number;
  page?: number;
  pageSize?: number;
  pageCount?: number;
  total: number;
}

export interface MenuLink {
  id: number;
  DisplayName: string;
  Link: string;
  LinkType?: LinkType;
  Links?: MenuLink[];
  IsVisibleAnonymous: boolean;
  __component?: string;
}

export type LinkType = 'Normal' | 'HeadingLink' | 'HeadingNoLink';

export interface ErrorResponse {
  /** The http status code such as 401, 404, etc.*/
  status: number;
  /** The error name such as NotFoundError.*/
  name: string;
  /** The error message.*/
  message: string;
  /** The error details.*/
  details: any;
}

/** Parameters to configure getting a list of items using the REST APIs.
 * See https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/api-parameters.html
 */
export interface ApiParameters {
  /** Used to sort the results by one or multiple fields:
   * You can append :asc or :desc to the field name to specify the sort order.
   * If you don't specify the sort order, it will default to ascending.
   * Example: sort=["field1:asc","field2:desc"]
   */
  sort?: string | string[];
  /** Used to filter the results by one or multiple fields:
   * Use an array of objects where the key is the field name and the value is the filter value.
   * For the filter value, see https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/filtering-locale-publication.html#filtering
   */
  filters?: object;
  /**
     * Queries can accept a populate parameter to populate various field types:
            - relations & media fields
            - components & dynamic zones
            - creator fields
       See https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/populating-fields.html#relation-media-fields
     */
  populate?: string | object;
  /** Used to limit the fields returned. */
  fields?: string[];
  pagination?:
    | {
        page: number;
        pageSize: number;
        withCount: boolean;
      }
    | {
        start: number;
        limit: number;
        withCount: boolean;
      };
  /** Used to select between the live or preview data. */
  publicationState?: 'live' | 'preview';
  /** Can be used to specify in which locale (language) to get the resources in. */
  locale?: string | string[];
}

export interface AddressData {
  data: DataResponse<AddressAttributes>[];
}
export interface AddressInfo {
  data: DataResponse<AddressAttributes>;
}

export interface AddressAttributes {
  Line1?: string;
  Line2?: string;
  City?: string;
  PostalCode?: string;
  IsPrimary?: boolean;
  country?: CountryData;
  country_subdivision?: CountrySubdivisionData;
  address_type?: AddressTypeData;
}

export interface AddressSaveData {
  data: {
    Line1?: string;
    Line2?: string;
    City?: string;
    PostalCode?: string;
    IsPrimary?: boolean;
    country?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    country_subdivision?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    address_type?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
  };
}

export interface AddressTypeData {
  data: DataResponse<AddressTypeAttributes>;
}

export interface AddressTypeAttributes {
  Name: string;
  Rank: number;
}

export interface AlbumData {
  data: DataResponse<AlbumAttributes>[];
}

export interface AlbumInfo {
  data: DataResponse<AlbumAttributes>;
}

export interface AlbumAttributes {
  Name: string;
  Description?: string;
  ImageCredits?: string;
  Location?: string;
  Thumbnail?: AlbumItemInfo;
  AlbumItems?: AlbumItemData;
  EmbeddedVideoItems?: EmbeddedVideoItemData;
  PDFItems?: PDFItemData;
  Company?: CompanyData;
  MediaCategory?: MediaCategoryData;
  IsDirectoryGallery?: boolean;
}

export interface AlbumSaveData {
  data: {
    Name?: string;
    Description?: string;
    ImageCredits?: string;
    Location?: string;
    Thumbnail?: number;
    AlbumItems?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    PDFItems?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    Company?: { disconnect?: [{ documentId?: string }]; connect?: [{ documentId?: string }] };
    MediaCategory?: number;
    IsDirectoryGallery?: boolean;
  };
}

export interface AlbumItemData {
  data: DataResponse<AlbumItemAttributes>[];
}

export interface AlbumItemInfo {
  data: DataResponse<AlbumItemAttributes>;
}

export interface AlbumItemAttributes {
  ImageCredit: string;
  Media: ImageInfo;
  ThumbnailFor?: AlbumInfo;
}

export interface AlbumItemSaveData {
  data: {
    ImageCredit?: string;
    Media?: number;
    Album?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    ThumbnailFor?: number;
  };
}

export interface AnswerOptionData {
  data: DataResponse<AnswerOptionAttributes>;
}

export interface AnswerOptionAttributes {
  Text: string;
  Rank: number;
  IsOnSearchFilter?: boolean;
  HasQualifier?: boolean;
  QualifierLabel?: string;
  IsQualifierRequired?: boolean;
}

export interface AuthenticationData {
  identifier: string;
  password: string;
  requestRefresh: boolean;
}

export interface AuthenticationResponse {
  jwt: string;
  refreshToken: string;
  user: {
    blocked: boolean;
    confirmed: boolean;
    createdAt: Date;
    email: string;
    id: number;
    provider: string;
    updatedAt: Date;
    username: string;
  };
}

export interface ChartbrewReportData {
  data: ChartbrewReportInfo;
}

export interface ChartbrewReportInfo {
  id: number;
  attributes: ChartbrewReportAttributes;
}

export interface ChartbrewReportAttributes {
  Name: string;
  Description: number;
  Rank: number;
  UID: string;
  Height: number;
}

export interface CountryData {
  data: DataResponse<CountryAttributes>;
}

export interface CountryAttributes {
  Name: string;
  SearchableName?: string;
  A2: string;
  A3: string;
  Number: number;
}

export interface CountrySubdivisionData {
  data: DataResponse<CountrySubdivisionAttributes>;
}

export interface CountrySubdivisionAttributes {
  Name: string;
  Code: string;
}

export interface CompanyData {
  data: DataResponse<CompanyInfo>;
}

export interface CompaniesData {
  data: DataResponse<CompanyInfo>[];
}

export interface CompanyInfo {
  Name: string;
  SearchableName: string;
  InvoiceCompanyName: string;
  Tagline?: string;
  Description?: string;
  Website?: string;
  IsActive?: boolean;
  IsArchived?: boolean;
  SearchResultsRank?: number;
  InternalNotes?: string;
  IsEmbarkPreferred?: boolean;
  IncludeInEmbarkBlackBook?: boolean;
  IncludeInEmbarkInsider?: boolean;
  CompanyStatus?: CompanyStatusData;
  AccountManagers?: PersonAtCompaniesData;
  LogoImage?: ImageInfo;
  SearchResultsThumbnail?: ImageInfo;
  SearchResultsLocation?: string;
  BannerImage?: ImageInfo;
  Addresses?: AddressData;
  EmailAddresses?: EmailAddressData;
  PhoneNumbers?: PhoneNumberData;
  SocialMediaAccounts?: SocialMediaData;
  company_types?: CompanyTypeData;
  company_sub_types?: CompanySubTypeData;
  AssociatedCompanies?: CompaniesData;
  Tags?: TagCollectionData;
  EventSpaces?: EventSpacesData;
  EventSpaceMaximum?: number;
  EventSpaceMaximumSeated?: number;
  NumberOfUnits?: number;
  NumberOfBedrooms?: number;
  Ingredients?: IngredientData;
  HighlightedIngredients?: IngredientData;
  GeographySearchItems?: GeographySearchItemsData;
  LanguagesSpoken?: LanguageCollectionData;
  UsefulLinks?: UsefulLinkData;
  CompanyQuestionAnswers?: CompanyQuestionAnswerData;
  InternalOverview?: string;
  InternalPositioning?: string;
  InternalWhy?: string;
  InternalPersonality?: string;
  InternalDrawbacks?: string;
  InternalPerspective?: string;
  InternalBestFor?: string;
  InsiderReviews?: string;
  IsVerified?: boolean;
  VerificationExpirationDate?: string;
  FirstReminderDate?: string;
  SecondReminderDate?: string;
  SubsequentReminderDate?: string;
  VerificationNote?: string;
  CompanyHistories?: CompanyHistoryData;
  PublicationNotes?: PublicationNoteData;
  IsValueOption?: boolean;
}

export interface CompanySaveData {
  data: {
    Name?: string;
    company_types?: { disconnect: { id?: number }[]; connect: { id?: number }[] };
    company_sub_types?: { disconnect: { id?: number }[]; connect: { id?: number }[] };
    Addresses?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    EmailAddresses?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    PhoneNumbers?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    SocialMediaAccounts?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    AssociatedCompanies?: { disconnect: { id?: number }[]; connect: { id?: number }[] };
    Tags?: { disconnect: { id?: number }[]; connect: { id?: number }[] };
    EventSpaces?: { disconnect: { id?: number }[]; connect: { id?: number }[] };
    Ingredients?: { disconnect: { id?: number }[]; connect: { id?: number }[] };
    HighlightedIngredients?: { disconnect: { id?: number }[]; connect: { id?: number }[] };
    GeographySearchItems?: { disconnect: { id?: number }[]; connect: { id?: number }[] };
    LanguagesSpoken?: { disconnect: { id?: number }[]; connect: { id?: number }[] };
    UsefulLinks?: { disconnect: { id?: number }[]; connect: { id?: number }[] };
    Description?: string;
    Tagline?: string;
    Website?: string;
    LogoImage?: number;
    SearchResultsThumbnail?: number;
    SearchResultsLocation?: string;
    BannerImage?: number;
    SearchResultsRank?: number;
    InternalNotes?: string;
    IsEmbarkPreferred?: boolean;
    IncludeInEmbarkBlackBook?: boolean;
    IncludeInEmbarkInsider?: boolean;
    EventSpaceMaximum?: number;
    EventSpaceMaximumSeated?: number;
    NumberOfUnits?: number;
    NumberOfBedrooms?: number;
    CompanyStatus?: number;
    InternalOverview?: string;
    InternalPositioning?: string;
    InternalWhy?: string;
    InternalPersonality?: string;
    InternalDrawbacks?: string;
    InternalPerspective?: string;
    InternalBestFor?: string;
    InsiderReviews?: string;
    IsValueOption?: boolean;
  };
}

export interface CompanyQuestionAnswerData {
  data: DataResponse<CompanyQuestionAnswerAttributes>[];
}

export interface CompanyQuestionAnswerAttributes {
  AnswerText: string;
  answer_option?: AnswerOptionData;
  Company?: CompanyData;
  EventSpace?: EventSpaceData;
  question?: QuestionData;
  QualifierAnswerText?: string;
}

export interface CompanyQuestionAnswerSaveData {
  data: {
    AnswerText?: string;
    QualifierAnswerText?: string;
    answer_option?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    question?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    company?: number;
    EventSpace?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
  };
}

export interface CompanyRelationshipData {
  data: DataResponse<CompanyRelationshipAttributes>[];
}

export interface CompanyRelationshipInfo {
  data: DataResponse<CompanyRelationshipAttributes>;
}

export interface CompanyRelationshipAttributes {
  Type: 'family' | 'representation' | 'management';
  Parent: CompanyData;
  Child: CompanyData;
}
export interface CompanyRelationshipSaveData {
  data: {
    Type: 'family' | 'representation' | 'management';
    Parent: string;
    Child: string;
  };
}

export interface CompanyStatusData {
  data: DataResponse<CompanyStatusInfo>;
}
export interface CompanyStatusInfo {
  DisplayName: string;
  IsActive?: boolean;
  Key: string;
  ProfileHeaderInfo?: string;
}

export interface CompanySubTypeData {
  data: DataResponse<CompanySubTypeAttributes>[];
}

export interface CompanySubTypeAttributes {
  Name: string;
  IsActive: boolean;
  Description: string;
  VerificationInstructions: string;
  company_types?: CompanyTypeData;
  Key?: string;
}

export interface CompanySubTypeItem {
  id: number;
  Name: string;
  IconName: string;
  Key: string;
}

export interface CompanyTypeData {
  data: DataResponse<CompanyTypeAttributes>[];
}

export interface CompanyTypeAttributes {
  Name: string;
  IsActive: boolean;
  Description: string;
  VerificationInstructions: string;
  company_sub_types?: CompanySubTypeData;
}

export interface CompanyTypeQuestionData {
  data: DataResponse<CompanyTypeQuestionAttributes>[];
}

export interface CompanyTypeQuestionAttributes {
  IsOnApplication: boolean;
  IsRequiredOnApplication: boolean;
  IsOnCompanyProfile: boolean;
  IsRequiredOnCompanyProfile: boolean;
  IsOnSearchFilter: boolean;
  FilterRank: number;
  company_type: CompanyTypeData;
  company_sub_type: CompanySubTypeData;
  question: QuestionData;
}

export interface CompanyHistoryData {
  data: DataResponse<CompanyHistoryAttributes>[];
}

export interface CompanyHistoryInfo {
  data: DataResponse<CompanyHistoryAttributes>;
}

export interface CompanyHistoryAttributes {
  Company?: CompanyData;
  Action: 'profile_verified' | 'verification_date_expired' | 'first_email_sent' | 'second_email_sent' | 'subsequent_email_sent';
  Name: string;
  Type: 'verification';
}

export interface CompanyVerificationData {
  data: DataResponse<CompanyVerificationInfo>;
}

export interface CompanyVerificationInfo {
  VerificationInstructions?: string;
}

export interface CompanyVerificationSaveData {
  data: {
    Notes?: string;
  };
}

export interface CompanyVerificationSaveResponse {
  data: {
    VerificationExpirationDate?: string;
  };
}

export interface DefaultSearchText {
  data: DataResponse<DefaultSearchTextInfo>;
}

export interface DefaultSearchTextInfo {
  Query: string;
}

export interface EmailAddressData {
  data: DataResponse<EmailAddressAttributes>[];
}
export interface EmailAddressInfo {
  data: DataResponse<EmailAddressAttributes>;
}

export interface EmailAddressAttributes {
  IsValidated: boolean;
  EmailAddress: string;
  IsPrimary?: boolean;
  email_address_type: EmailAddressTypeData;
}

export interface EmailAddressTypeData {
  data: DataResponse<EmailAddressTypeAttributes>;
}

export interface EmailAddressTypeAttributes {
  Name: string;
  Rank: number;
}

export interface EmailAddressSaveData {
  data: {
    EmailAddress?: string;
    IsPrimary?: boolean;
    email_address_type?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
  };
}

export interface EmailAddressValidationData {
  data: {
    inputData: string;
    isDisposable: boolean;
    isKnownSpammerDomain: boolean;
    isMailServerDefined: boolean;
    isSyntaxValid: boolean;
    isValid: boolean;
  };
}

export interface EmbeddedVideoItemData {
  data: DataResponse<EmbeddedVideoItemAttributes>[];
}

export interface EmbeddedVideoItemInfo {
  data: DataResponse<EmbeddedVideoItemAttributes>;
}

export interface EmbeddedVideoItemAttributes {
  Name: string;
  VideoHost: string;
  HostVideoId: string;
  MediaCategory?: MediaCategoryData;
  album?: number;
}

export interface EmbeddedVideoItemSaveData {
  data: {
    Name: string;
    VideoHost: string;
    HostVideoId: string;
    album?: number;
    MediaCategory?: number;
  };
}

export interface EventSpaceData {
  data: DataResponse<EventSpaceAttributes>;
}

export interface EventSpacesData {
  data: DataResponse<EventSpaceAttributes>[];
}

export interface EventSpaceInfo {
  id: number;
  attributes: EventSpaceAttributes;
}

export interface EventSpaceAttributes {
  Name?: string;
  TotalSquareMeters?: number;
  CeilingHeightInMeters?: number;
  EventSpaceLayouts?: EventSpaceLayoutData;
  Company?: CompanyData;
}

export interface EventSpaceSaveData {
  data: {
    Name?: string;
    TotalSquareMeters?: number;
    CeilingHeightInMeters?: number;
    EventSpaceLayouts?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    Company?: string;
  };
}

export interface EventSpaceLayoutData {
  data: DataResponse<EventSpaceLayoutAttributes>[];
}

export interface EventSpaceLayoutInfo {
  id: number;
  attributes: EventSpaceLayoutAttributes;
}

export interface EventSpaceLayoutAttributes {
  LayoutType: LayoutTypeData;
  MaxCapacity: number;
  EventSpace: EventSpaceData;
}

export interface EventSpaceLayoutSaveData {
  data: {
    LayoutType?: number;
    MaxCapacity?: number;
    EventSpace?: number;
  };
}

export interface FileAttachmentData {
  data: DataResponse<FileAttachmentAttributes>[];
}

export interface FileAttachmentAttributes {
  name?: string;
  alternativeText: string;
  caption?: string;
  ext: string;
  mime: string;
  size?: number;
  url: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface FooterContentData {
  data: DataResponse<FooterContentInfo>;
}

export interface FooterContentInfo {
  Address?: AddressInfo;
  EmailAddress?: EmailAddressInfo;
  EmailSubject?: string;
  PhoneNumber?: PhoneNumberInfo;
  SocialMedia?: SocialMediaData;
  CopyrightEntityName?: string;
  CopyrightYear?: string;
  CopyrightText?: string;
}

export interface FuzzySearchMatch {
  indices: number[];
  value: string;
  key: string;
  refIndex: number;
}

export interface FuzzySearchResponse {
  searchResults: FuzzySearchResult[];
  companySubtypes: CompanySubTypeItem[];
  filters: SearchFilterAttributes[];
  questionAnswerMap: QuestionAnswerMap;
}

export interface FuzzySearchResult {
  item: FuzzySearchResultItem; // this will be strongly typed later, once we know what the results will look like
  refIndex: number;
  matches: FuzzySearchMatch[];
  score: number;
  originalScore?: number;
  Type: 'Company' | 'Person';
  IsEmbarkPreferred?: boolean;
  firstSignificantDigit?: number;
  numerator?: number;
  denominator?: number;
  adjustment?: number;
  questionAnswerMap?: QuestionAnswerMap;
}

export interface FuzzySearchResultItem {
  id: number;
  documentId: string;
  Addresses: AddressData;
  Company: CompanyInfo;
  CompanyName: string;
  CompanyId: number;
  EventSpaceMaximum?: number;
  NumberOfUnits?: number;
  NumberOfBedrooms?: number;
  DirectoryName?: string;
  FirstName?: string;
  IsDefaultProfile?: boolean;
  JobTitle?: string;
  LastName?: string;
  MiddleName?: string;
  ProfileImage?: ImageInfo;
  SearchResultsThumbnail?: ImageInfo;
  SearchResultsLocation?: string;
  PhoneNumberFormatted?: string;
  PhoneNumberE164?: string;
  EmailAddress?: string;
  ProviderTypeIcons?: string[];
  company_sub_types?: CompanySubTypeItem[];
  GeographySearchItems: GeographySearchItemResult[];
  GeographySearchItems1: GeographySearchItemResult[];
  GeographySearchItems2: GeographySearchItemResult[];
  GeographySearchItems3: GeographySearchItemResult[];
  GeographySearchItems4: GeographySearchItemResult[];
  GeographySearchItems5: GeographySearchItemResult[];
  GeographySearchItems6: GeographySearchItemResult[];
  GeographySearchItems7: GeographySearchItemResult[];
  GeographySearchItems8: GeographySearchItemResult[];
  GeographySearchItems9: GeographySearchItemResult[];
}

export interface GeographySearchItemData {
  data: DataResponse<GeographySearchItemAttributes>;
}

export interface GeographySearchItemsData {
  data: DataResponse<GeographySearchItemAttributes>[];
}

export interface GeographySearchItemInfo {
  id: number;
  attributes: GeographySearchItemAttributes;
}

export interface GeographySearchItemAttributes {
  Name?: string;
  SearchableName?: string;
  Weight?: number;
  IsActive?: boolean;
  Parents?: GeographySearchItemsData;
  Children?: GeographySearchItemsData;
  Companies?: CompaniesData;
}

export interface GeographySearchItemResult {
  id: number;
  Name: string;
  SearchableName: string;
  Weight: number;
  Count: number;
  Score: number;
}

export interface GeographySearchItemSaveData {
  data: {
    Name?: string;
    Weight?: number;
    IsActive?: boolean;
    Parents?: { disconnect: { id?: number }[]; connect: { id?: number }[] };
    Children?: { disconnect: { id?: number }[]; connect: { id?: number }[] };
  };
}

export interface GeographySearchItemTreeNode {
  id: number;
  parentId: number | null;
  geographySearchItem: DataResponse<GeographySearchItemAttributes>;
  children: GeographySearchItemTreeNode[];
}

export interface HelpPageData {
  data: DataResponse<HelpPageAttributes>[];
}

export interface HelpPageAttributes {
  Title: string;
  Body?: string;
  Slug: string;
  Rank: number;
  IsActive: boolean;
  IsEmbarkEditorOnly: boolean;
  Parents?: HelpPageData;
  Children?: HelpPageData;
  Attachments?: FileAttachmentData;
}

export interface HelpPageTreeNode {
  id: number;
  parentId: number | null;
  helpPage: DataResponse<HelpPageAttributes>;
  children: HelpPageTreeNode[];
}

export interface HTMLContentData {
  data: DataResponse<HTMLContentAttributes>[];
}

export interface HTMLContentAttributes {
  Page: string;
  Pane: string;
  Rank: number;
  Content: string;
}

export interface HTMLContentSaveData {
  data: {
    Page: string;
    Pane: string;
    Rank: number;
    Content: string;
  };
}

export interface ImageInfo {
  data: ImageData;
}

export interface ImageInfoFlat {
  id: number;
  alternativeText: string;
  url: string;
  size?: number;
  formats?: ImageFormatsInfo;
  caption?: string;
  name?: string;
}

export interface ImageData {
  id: number;
  attributes: ImageAttributes;
}

export interface ImageAttributes {
  alternativeText: string;
  url: string;
  size?: number;
  formats?: ImageFormatsInfo;
  caption?: string;
  name?: string;
}

export interface ImageResponseData {
  id: number;
  name: string;
  alternativeText?: string;
  caption?: string;
  width: number;
  height: number;
  formats: ImageFormatsInfo;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl?: string;
  provider: string;
  provider_metadata?;
  string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ImageFormatsInfo {
  xlarge?: ImageFormatInfo;
  large?: ImageFormatInfo;
  medium?: ImageFormatInfo;
  small?: ImageFormatInfo;
  thumbnail?: ImageFormatInfo;
  xsmall?: ImageFormatInfo;
}

export interface ImageFormatInfo {
  ext: string;
  hash: string;
  height: number;
  mime: string;
  name: string;
  path: string;
  size: number;
  url: string;
  width: number;
}

export interface IngredientData {
  data: DataResponse<IngredientAttributes>[];
}

export interface IngredientAttributes {
  Name: string;
  Description?: string;
  IsActive?: boolean;
}

export interface HTMLContentSaveData {
  data: {
    Page: string;
    Pane: string;
    Rank: number;
    Content: string;
  };
}

export interface LanguageSingularData {
  data: LanguageInfo;
}

export interface LanguageCollectionData {
  data: DataResponse<LanguageAttributes>[];
}

export interface LanguageInfo {
  id: number;
  attributes: LanguageAttributes;
}

export interface LanguageAttributes {
  EnglishName?: string;
  NativeName?: string;
  SearchableName?: string;
  A2?: string;
  A3?: string;
  Rank?: number;
}

export interface LayoutTypeData {
  data: LayoutTypeInfo;
}

export interface LayoutTypeInfo {
  id: number;
  attributes: LayoutTypeAttributes;
}

export interface LayoutTypeAttributes {
  Name: string;
  Description: number;
  IsActive: boolean;
}

export interface MediaCategoryData {
  data: DataResponse<MediaCategoryAttributes>;
}

export interface MediaCategoryAttributes {
  Name: string;
  Description: number;
  Key?: string;
  IsActive: boolean;
}

export interface MinPopularLocationScore {
  data: DataResponse<MinPopularLocationScoreInfo>;
}

export interface QuestionAnswerMap {
  [key: string]: number[];
}

export interface MinPopularLocationScoreInfo {
  Score: number;
}

export interface PDFItemData {
  data: DataResponse<PDFItemAttributes>[];
}

export interface PDFItemInfo {
  data: DataResponse<PDFItemAttributes>;
}

export interface PDFItemAttributes {
  Name: string;
  PDF?: ImageInfo;
  MediaCategory?: MediaCategoryData;
  Album?: AlbumInfo;
}

export interface PDFItemSaveData {
  data: {
    Name: string;
    MediaCategory?: number;
    Album?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    PDF?: number;
  };
}

export interface PersonData {
  data: DataResponse<PersonInfo>;
}
export interface PersonInfo {
  FirstName?: string;
  MiddleName?: string;
  LastName?: string;
  DirectoryName?: string;
  PreferredName?: string;
  Addresses?: AddressData;
  EmailAddresses?: EmailAddressData;
  PhoneNumbers?: PhoneNumberData;
  prefix?: PrefixData;
  ProfileImage?: ImageInfo;
  Pronoun?: PronounData;
  SocialMediaAccounts?: SocialMediaData;
  Suffix?: SuffixData;
  user?: UserData;
  PreferredLanguage?: LanguageSingularData;
  LanguagesSpoken?: LanguageCollectionData;
  IsActive?: boolean;
  IsArchived?: boolean;
  PersonAtCompanies?: PersonAtCompaniesData;
}

export interface PersonSaveData {
  data: {
    FirstName?: string;
    MiddleName?: string;
    LastName?: string;
    DirectoryName?: string;
    PreferredName?: string;
    Addresses?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    EmailAddresses?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    PhoneNumbers?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    SocialMediaAccounts?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    prefix?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    Pronoun?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    Suffix?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    PreferredLanguage?: { disconnect?: [{ id?: number }?]; connect?: [{ id?: number }?] };
    LanguagesSpoken?: { disconnect: { id?: number }[]; connect: { id?: number }[] };
    ProfileImage?: number;
    IsActive?: boolean;
  };
}

export interface PersonAtCompanyData {
  data: DataResponse<PersonAtCompanyInfo>;
}

export interface PersonAtCompaniesData {
  data: DataResponse<PersonAtCompanyInfo>[];
}

export interface PersonAtCompanyInfo {
  JobTitle?: string;
  Bio?: string;
  CanManageCompanyDetails?: boolean;
  CanManageCompanyStaff?: boolean;
  IsActive?: boolean;
  IsArchived?: boolean;
  IsDefaultProfile?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  Company?: CompanyData;
  Person?: PersonData;
  Addresses?: AddressData;
  EmailAddresses?: EmailAddressData;
  PhoneNumbers?: PhoneNumberData;
  SocialMediaAccounts?: SocialMediaData[];
  PrimaryContactType?: PrimaryContactTypeData;
  IsBlackbookProfileContact?: boolean;
  AccountsManaged?: CompanyData[];
  DoCopyOnImportantEmails?: boolean;
  ReceiveRFPNotifications?: boolean;
}

export interface PersonAtCompanySaveData {
  data: {
    JobTitle?: string;
    Bio?: string;
    Addresses?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    EmailAddresses?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    PhoneNumbers?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    SocialMediaAccounts?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    IsActive?: boolean;
    IsDefaultProfile?: boolean;
    CanManageCompanyDetails?: boolean;
    CanManageCompanyStaff?: boolean;
    Person?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    Company?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    PrimaryContactType?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    IsBlackbookProfileContact?: boolean;
    AccountsManaged?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    DoCopyOnImportantEmails?: boolean;
    ReceiveRFPNotifications?: boolean;
  };
}

export interface PhoneNumberData {
  data: DataResponse<PhoneNumberAttributes>[];
}

export interface PhoneNumberInfo {
  data: DataResponse<PhoneNumberAttributes>;
}

export interface PhoneNumberAttributes {
  RawFormat?: string;
  IsValidated: boolean;
  E164Format?: string;
  InternationalFormat?: string;
  NationalFormat?: string;
  IsPrimary?: boolean;
  IsWhatsApp?: boolean;
  country?: CountryData;
  phone_number_type?: PhoneNumberTypeData;
}

export interface PhoneNumberSaveData {
  data: {
    RawFormat?: string;
    IsPrimary?: boolean;
    IsWhatsApp?: boolean;
    country?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
    phone_number_type?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
  };
}

export interface PhoneNumberTypeData {
  data: DataResponse<PhoneNumberTypeAttributes>;
}

export interface PhoneNumberTypeAttributes {
  Name: string;
  Rank: number;
}

export interface PolicyData {
  data: PolicyInfo;
}

export interface PolicyInfo {
  id: number;
  attributes: PolicyAttributes;
}

export interface PolicyAttributes {
  Title: string;
  PolicyType: string;
  Version: number;
  Policy: string;
  isCurrent: boolean;
}

export interface PrefixData {
  data: PrefixInfo;
}

export interface PrefixInfo {
  id: number;
  attributes: PrefixAttributes;
}

export interface PrefixAttributes {
  Name: string;
  Rank: number;
}

export interface PrimaryContactTypeData {
  data: PrimaryContactTypeInfo;
}

export interface PrimaryContactTypeInfo {
  id: number;
  attributes: PrimaryContactTypeAttributes;
}

export interface PrimaryContactTypeAttributes {
  Name: string;
  IsActive: boolean;
}

export interface PronounData {
  data: PronounInfo;
}

export interface PronounInfo {
  id: number;
  attributes: PronounAttributes;
}

export interface PronounAttributes {
  Name: string;
  Rank: number;
}

export interface PublicationNoteData {
  data: DataResponse<PublicationNoteAttributes>[];
}

export interface PublicationNoteInfo {
  data: DataResponse<PublicationNoteAttributes>;
}

export interface PublicationNoteAttributes {
  Name: string;
  Note: string;
  Company: CompanyData;
}

export interface QuestionData {
  data: DataResponse<QuestionAttributes>;
}

export interface QuestionAttributes {
  Text: string;
  Description: string;
  ProfileLabelText: string;
  FilterLabelText: string;
  question_type: QuestionTypeData;
  answer_options: AnswerOptionData[];
  company_question_answers?: CompanyQuestionAnswerData;
  IncludeInEmbarkBlackBook?: boolean;
  IncludeInEmbarkInsider?: boolean;
  IsInGlance?: boolean;
  IsInOverview?: boolean;
  Category:
    | ''
    | QuestionCatetory.Accommodations
    | QuestionCatetory.Concessions
    | QuestionCatetory.Essentials
    | QuestionCatetory.GeneralEventParameters
    | QuestionCatetory.OnSiteActivities
    | QuestionCatetory.ProductPositioning
    | QuestionCatetory.PropertyAccess
    | QuestionCatetory.PropertyFeatures
    | QuestionCatetory.SpecialtyTravel
    | QuestionCatetory.VenueSpecificDetails;
  Subcategory?:
    | ''
    | QuestionSubcetegory.Accessibility
    | QuestionSubcetegory.Banquet
    | QuestionSubcetegory.Beach
    | QuestionSubcetegory.Buyout
    | QuestionSubcetegory.Commission
    | QuestionSubcetegory.Curfew
    | QuestionSubcetegory.General
    | QuestionSubcetegory.InternalMovement
    | QuestionSubcetegory.KosherEvents
    | QuestionSubcetegory.LoadingTeardown
    | QuestionSubcetegory.Permits
    | QuestionSubcetegory.Pool
    | QuestionSubcetegory.ProductionParameters
    | QuestionSubcetegory.Recommendations
    | QuestionSubcetegory.Restrictions
    | QuestionSubcetegory.ShippingImporting
    | QuestionSubcetegory.Spa
    | QuestionSubcetegory.StaffVendorsAccommodating
    | QuestionSubcetegory.StaffVendorsGeneral
    | QuestionSubcetegory.StaffVendorsVehiclesParking
    | QuestionSubcetegory.Tenting;
  Rank: number;
}

export interface QuestionTypeData {
  data: DataResponse<QuestionTypeAttributes>;
}

export interface QuestionTypeAttributes {
  Name: string;
  Description: string;
}

export interface RoleData {
  data: DataResponse<RoleAttributes>;
}

export interface RoleAttributes {
  name: string;
  description: string;
  type: string;
}

export interface RoleInfo {
  id: number;
  name: string;
}

export interface SavedFavoriteListData {
  data: DataResponse<SavedFavoriteListAttributes>[];
}

export interface SavedFavoriteListInfo {
  data: DataResponse<SavedFavoriteListAttributes>;
}

export interface SavedFavoriteListAttributes {
  Name: string;
  Description: string;
  Owner: PersonData;
  ReppingAs?: PersonAtCompanyData;
  EventDate?: Date;
  ApproximateDate?: string;
  IsActive: boolean;
  Type: 'event' | 'favorite';
  Companies?: CompaniesData;
}

export interface SavedFavoriteListCompany {
  id: number;
  Name: string;
  SearchResultsLocation: string;
  SearchResultsThumbnail?: ImageInfoFlat;
  company_sub_types: CompanySubTypeItem[];
}

export interface SavedFavoriteListSaveItemResults {
  data: SavedFavoriteListItem[];
}

export interface SavedFavoriteListItem {
  id: number;
  documentId: string;
  Name: string;
  Description: string;
  Owner: PersonData;
  ReppingAs?: PersonAtCompanyData;
  EventDate?: Date;
  ApproximateDate?: string;
  IsActive: boolean;
  Type: 'event' | 'favorite';
  Companies?: SavedFavoriteListCompany[];
}

export interface SavedFavoriteListSaveData {
  data: {
    Name?: string;
    Description?: string;
    Owner?: number;
    ReppingAs?: number;
    EventDate?: Date;
    ApproximateDate?: string;
    IsActive?: boolean;
    Type?: 'event' | 'favorite';
    Companies?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
  };
}

export interface SearchFilterAttributes {
  id: number;
  Text: string;
  FilterLabelText: string;
  FilterRank: number;
  answer_options: SearchFilterAnswerOptionAttributes[];
  isExpanded: boolean;
}

export interface SearchFilterAnswerOptionAttributes {
  id: number;
  Text: string;
  Rank: number;
}

export interface SocialMediaData {
  data: DataResponse<SocialMediaAttributes>[];
}

export interface SocialMediaInfo {
  id: number;
  attributes: SocialMediaAttributes;
}

export interface SocialMediaAttributes {
  Name: string;
  URL: string;
  social_media_type: SocialMediaTypeData;
}

export interface SocialMediaTypeData {
  data: SocialMediaTypeInfo;
}

export interface SocialMediaTypeInfo {
  id: number;
  attributes: SocialMediaTypeAttributes;
}

export interface SocialMediaTypeAttributes {
  Name: string;
  Rank: number;
  BaseURL: string;
}

export interface SocialMediaSaveData {
  data: {
    Name?: string;
    URL?: string;
    social_media_type?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
  };
}

export interface SuffixData {
  data: SuffixInfo;
}

export interface SuffixInfo {
  id: number;
  attributes: SuffixAttributes;
}

export interface SuffixAttributes {
  Name: string;
  Rank: number;
}

export interface TagCollectionData {
  data: DataResponse<TagAttributes>[];
}

export interface TagInfo {
  id: number;
  attributes: TagAttributes;
}

export interface TagAttributes {
  Name: string;
  Category: string;
}

export interface TagSaveData {
  data: {
    Name: string;
    Category: string;
    Companies?: { disconnect?: [{ id?: number }]; connect?: [{ id?: number }] };
  };
}

export interface TagsData {
  data: DataResponse<TagInfo>[];
}

export interface UsefulLinkData {
  data: DataResponse<UsefulLinkAttributes>[];
}

export interface UsefulLinkInfo {
  data: DataResponse<UsefulLinkAttributes>;
}

export interface UsefulLinkAttributes {
  URL: string;
  DisplayText: string;
  Rank: number;
  MediaCategory: MediaCategoryData;
}

export interface UsefulLinkSaveData {
  data: {
    URL: string;
    DisplayText: string;
    Rank: number;
    MediaCategory: number;
    company?: string; // company documentId
  };
}

export interface UserData {
  data: UserDataInfo;
}

export interface UserDataInfo {
  id: number;
  documentId: string;
  attributes: UserDataAttributes;
}

export interface UserDataAttributes {
  username: string;
  email: string;
  confirmed: boolean;
  blocked: boolean;
  role: RoleData;
}

export interface UserInfo {
  id: number;
  documentId: string;
  username: string;
  email: string;
  IsEmbarkEditor: boolean;
  role: RoleInfo;
}

export interface UserAcceptedPolicyData {
  data: DataResponse<UserAcceptedPolicyAttributes>[];
}

export interface UserAcceptedPolicyInfo {
  id: number;
  attributes: UserAcceptedPolicyAttributes;
}

export interface UserAcceptedPolicyAttributes {
  PolicyType: string;
  Version: number;
  UserId: number;
  UserDocumentId: string;
}

export interface UserAcceptedPolicySaveData {
  data: {
    PolicyType: string;
    Version: number;
    UserId: number;
    UserDocumentId: string;
  };
}
