import { ApiParameters, ClientBase, PersonAtCompanyInfo, CollectionSuccessResponse, PersonAtCompanyData, PersonAtCompanySaveData, SuccessResponse } from './client-base';

export class PersonAtCompanyClient extends ClientBase {
  private endpoint = '/api/people-at-companies';

  constructor() {
    super();
  }

  public getPersonsAtCompanies(parameters?: ApiParameters) {
    return new Promise<CollectionSuccessResponse<PersonAtCompanyInfo>>((resolve, reject) => {
      let query = this.stringifyParameters(parameters);
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}?${query}`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Strapi-Response-Format': 'v4',
              'Authorization': `Bearer ${token}`,
            },
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }

  public getPersonAtCompany(personAtCompanyId: string | number, parameters?: ApiParameters) {
    this.checkForDocumentId(personAtCompanyId, 'PersonAtCompanyClient', 'getPersonAtCompany');
    return new Promise<PersonAtCompanyData>((resolve, reject) => {
      let query = this.stringifyParameters(parameters);
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}/${personAtCompanyId}?${query}`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Strapi-Response-Format': 'v4',
              'Authorization': `Bearer ${token}`,
            },
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }

  public securityCheck(personAtCompanyId: string | number) {
    this.checkForDocumentId(personAtCompanyId, 'PersonAtCompanyClient', 'securityCheck');
    return new Promise<SecurityCheckResponse>((resolve, reject) => {
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}/security/${personAtCompanyId}`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Strapi-Response-Format': 'v4',
              'Authorization': `Bearer ${token}`,
            },
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }

  public addPersonAtCompany(data: PersonAtCompanySaveData) {
    return new Promise<SuccessResponse<PersonAtCompanyInfo>>((resolve, reject) => {
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              'Strapi-Response-Format': 'v4',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(data),
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }

  public addNewPersonAtCompany(data: NewPersonAtCompanyData) {
    return new Promise<SuccessResponse<PersonAtCompanyInfo>>((resolve, reject) => {
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}/new-team-member`, {
            method: 'POST',
            headers: {
              'Content-type': 'application/json',
              'Strapi-Response-Format': 'v4',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(data),
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }

  public updatePersonAtCompany(personAtCompanyId: string | number, data: PersonAtCompanySaveData) {
    this.checkForDocumentId(personAtCompanyId, 'PersonAtCompanyClient', 'updatePersonAtCompany');
    return new Promise<SuccessResponse<PersonAtCompanySaveData>>((resolve, reject) => {
      this.getCurrentAuthToken()
        .then(token => {
          fetch(`${this.baseUrl}${this.endpoint}/${personAtCompanyId}`, {
            method: 'PUT',
            headers: {
              'Content-type': 'application/json',
              'Strapi-Response-Format': 'v4',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(data),
          })
            .then(response => resolve(this.processResponse(response)))
            .catch(error => reject(error));
        })
        .catch(() => {
          window.location.replace('/logout');
        }); // The jwt is missing, invalid, or corrupted, log the user out.);
    });
  }
}
export interface SecurityCheckResponse {
  canManageProfileFields: boolean;
  canManageActiveArchiveFlags: boolean;
  canManageCompanyDetailsFlag: boolean;
  canManageCompanyStaffFlag: boolean;
  canViewPersonAtCompany: boolean;
  isEmbarkEditor: boolean;
}

export interface NewPersonAtCompanyData {
  LoginEmail: string;
  FirstName: string;
  LastName: string;
  CompanyId: number;
  JobTitle: string;
  WorkEmail: string;
  WorkPhoneCountryId: number;
  WorkPhoneNumber: string;
  SendWelcomeEmail: boolean;
}
